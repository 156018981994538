import { InboxOutlined, DownloadOutlined } from '@ant-design/icons';
import { message, Upload, Button, Spin, ConfigProvider } from 'antd';
import { useIndexedDB } from "react-indexed-db-hook";
import { useEffect, useState, useRef } from "react";
import { json, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import { fabric } from 'fabric';
import JSZip from 'jszip';
import TemplateEditor from './TemplateEditor';
import Canvas from "./Canvas";
import SideControls from './SideControls';
import '../styles/Canvas.css'
import '../styles/Generator.css'

const { Dragger } = Upload;

function GenerateTemplates() {
  const [fileList, setFileList] = useState([]);
  const [apiCalled, setApiCalled] = useState(false);
  const [form, setForm] = useState(new FormData());
  const [currentDesignImg, setCurrentDesignImg] = useState(null);
  const [generated, setGenerated] = useState(false);
  const { getAll } = useIndexedDB('templateDB')
  const navigate = useNavigate();
  const location = useLocation();
  const [log, setLog] = useState(null);
  const [designs, setDesigns] = useState(null);
  const canvasRef = useRef(null);
  const [upperDownloading, setDownloading] = useState(false);


  const generateLocal = () => {
    setApiCalled(true);
    async function cropImagesred() {
      const tempDesignList = []
      for (let index = 0; index < fileList.length; index++) {
        const uncroppedFile = fileList[index]
        const tempForm = new FormData();
        tempForm.append('design', uncroppedFile)
        const local_url = 'http://127.0.0.1:5000/crop_image';
        const cloud_url = 'https://etsy-backend-production-0384.up.railway.app/crop_image';
        const response = await fetch(cloud_url, {
          method: 'POST',
          body: tempForm,
        })
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const jsonResponse = await response.json();
        const blob =  base64ToBlob(jsonResponse.result);

        // Create a File object from the Blob
        const file = new File([blob], index + "design.png", { type: "image/png" });
        const reader = new FileReader();

        reader.onload = function (e) {
          const dataURL = e.target.result;
          tempDesignList.push({
            name: index + "design.png",
            data: dataURL
          })
          console.log({
            name: index + "design.png",
            data: dataURL
          })
        };
        reader.readAsDataURL(file);

      }
      return tempDesignList
    }
    async function cropImages() {
      const tempDesignList = [];
    
      for (let index = 0; index < fileList.length; index++) {
        const uncroppedFile = fileList[index];
        const tempForm = new FormData();
        tempForm.append('design', uncroppedFile);
    
        const cloud_url = 'https://etsy-backend-production-0384.up.railway.app/crop_image';
    
        try {
          const response = await fetch(cloud_url, {
            method: 'POST',
            body: tempForm,
          });
    
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
    
          const jsonResponse = await response.json();
          const blob = base64ToBlob(jsonResponse.result);
    
          // Create a File object from the Blob
          const file = new File([blob], index + 'design.png', { type: 'image/png' });
    
          const dataURL = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(e.target.result);
            reader.readAsDataURL(file);
          });
    
          tempDesignList.push({
            name: index + 'design.png',
            data: dataURL,
          });
    
          console.log({
            name: index + 'design.png',
            data: dataURL,
          });
        } catch (error) {
          console.error('Error processing image:', error);
          // Handle the error or rethrow it based on your needs
        }
      }
    
      return tempDesignList;
    }
    
    cropImages().then((tempDesignList) => {
      setGenerated(true);
      setDesigns(tempDesignList);
      setCurrentDesignImg(tempDesignList[0])
    })
  }


  useEffect(() => {
    console.log('downloading?')
    if (upperDownloading) {

      const generateImageUrls = async (designs, log) => {
        const imageUrls = [];
      
        await Promise.all(
          designs.map(async (currDesign) => {
            await Promise.all(
              log.map(async (map) => {
                const item = map.get(currDesign.name);
                const tempCanvas = new fabric.Canvas();
      
                const dataURL = await new Promise((resolve) => {
                  const reader = new FileReader();
                  reader.onload = (e) => resolve(e.target.result);
                  reader.readAsDataURL(item.file_object);
                });
      
                const img = new Image();
                img.src = dataURL;
      
                await new Promise((resolve) => {
                  img.onload = () => resolve();
                });
      
                const fabricImage = new fabric.Image(img);
                fabricImage.set({ left: 0, top: 0, selectable: false });
                tempCanvas.setDimensions({
                  width: fabricImage.width,
                  height: fabricImage.height,
                });
      
                fabricImage.scaleToWidth(tempCanvas.width);
                tempCanvas.add(fabricImage);
      
                const urlimg = await new Promise((resolve) => {
                  fabric.Image.fromURL(currDesign.data, (img) => resolve(img));
                });
      
                const percentage = tempCanvas.width / 100;
                const scaleX =
                  typeof item.canvas_width === 'undefined'
                    ? (item.rectangle_dims[0] * percentage) / urlimg.width
                    : (item.scaleX * tempCanvas.width) / item.canvas_width;
                const scaleY =
                  typeof item.canvas_height === 'undefined'
                    ? (item.rectangle_dims[1] * percentage) / urlimg.height
                    : (item.scaleY * tempCanvas.height) / item.canvas_height;
      
                urlimg.set({
                  left: item.rectangle_coords[0] * percentage,
                  top: item.rectangle_coords[1] * percentage,
                  scaleX: scaleX,
                  scaleY: scaleY,
                  angle: item.rectangle_orientation,
                });
      
                tempCanvas.add(urlimg);
      
                var link = document.createElement('a');
                link.download = 'example';
                link.href = tempCanvas.toDataURL({
                  format: 'jpeg',
                  quality: 0.8,
                });
                imageUrls.push(link.href);
              })
            );
          })
        );
      
        return imageUrls;
      };
      
      
      
      

      function downloadImagesAsZip(imageUrls) {
        const zip = new JSZip();

        // Use a loop to fetch and add each image to the zip file
        const promises = imageUrls.map((imageUrl, index) => {
          return fetch(imageUrl)
            .then(response => response.blob())
            .then(blob => {
              zip.file(`image_${index + 1}.jpg`, blob);
            });
        });

        // Wait for all promises to resolve before creating the zip file
        Promise.all(promises)
          .then(() => {
            // Generate the zip file
            return zip.generateAsync({ type: 'blob' });
          })
          .then(blob => {
            // Create a download link
            const a = document.createElement('a');
            const url = URL.createObjectURL(blob);
            a.href = url;
            a.download = 'images.zip';
            // Append the link to the document
            document.body.appendChild(a);
            // Trigger a click event to start the download
            a.click();
            // Remove the link from the document
            document.body.removeChild(a);
            // Revoke the object URL to free up resources
            URL.revokeObjectURL(url);
          })
          .catch(error => {
            console.error('Error:', error);
          });
      }

      generateImageUrls(designs, log)
        .then((imageUrls) => {
          console.log('Generated image URLs');
          downloadImagesAsZip(imageUrls);
          setDownloading(false);
        })
        .catch((error) => {
          console.error('Error generating image URLs:', error);
        });

      
    }
  }
    , [upperDownloading])


  const generate = () => {
    setApiCalled(true);
    form.append('design', fileList[0])
    form.append('canvasWidth', localStorage.getItem('canvasWidth'))
    setForm(form)
    const test_url_cloud = 'https://etsy-backend-production-0384.up.railway.app/test_post';
    const test_url = 'http://127.0.0.1:8000/test_post';
    const local_url = 'http://127.0.0.1:5000/process_data';
    const cloud_url = 'https://etsy-backend-production-0384.up.railway.app/process_data';
    fetch(local_url, {
      method: 'POST',
      body: form,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((images) => {
        console.log(images)
        const files = []
        images.result.forEach((image, index) => {
          // Convert base64 string to Blob
          const blob = base64ToBlob(image);

          // Create a File object from the Blob
          const file = new File([blob], index + "image.png", { type: "image/png" });
          files.push(file);
          console.log(file);
        })
        location.state.fileNames = files
        // navigate('/generate',{ state: { collectionName: 'generation_result', fileNames: files, toggleRectangle: false} });
        setGenerated(true)
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }


  // Helper function to convert base64 string to Blob
  function base64ToBlob(base64String) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: "image/png" });
  }

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const tempFiles = fileList
      tempFiles.push(file)

      setFileList(tempFiles)
      return false;

    },
    fileList,
  };

  const customButton = () =>
  (
    <Button className=" mt-2 bg-white text-[#78716c] w-5/6 h-auto" icon={<DownloadOutlined />} onClick={() => { setDownloading(!upperDownloading) }}>
      Download <br/>Images
    </Button>
  );


  return (
    <div className='generate-templates'>
      {!apiCalled ?
        <div className="custom-upload-container ">
          <Dragger {...props} multiple accept='image/png, image/jpeg' >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Upload Custom Design Here
            </p>
          </Dragger>

          <Button className="generate-button-container" onClick={generateLocal}>
            Generate Templates
          </Button>

        </div>
        :

        !generated  ?
          <div className='loading-wrapper'>
            <Spin tip="Loading" size="large" >
              <div className='content'></div>
            </Spin>
          </div>

          :

          upperDownloading ?  <div className='loading-wrapper'>
          <Spin tip="Loading" size="large" >
            <div className='content'></div>
          </Spin>
        </div> :

          <div>
            <TemplateEditor currentDesignImg={currentDesignImg} setCurrentDesignImg={setCurrentDesignImg} customButton={customButton} downloading={upperDownloading} toggleRectangle={true} designs={designs} changeLog={(x) => setLog(x)} />
          </div>

      }
    </div>
  );
}

export default GenerateTemplates;
