const buttonData = [
  {
    src: "/tops.svg",
    name: "Tops",
    dropdownItems: ["T-Shirts", "Long-Sleeves", "Crop-Tops"],
  },
  {
    src: "/outerwear.svg",
    name: "Jackets",
    dropdownItems: ["Jackets", "Hoodies", "Sweaters"],
  },
  {
    src: "/legwear.svg",
    name: "Legwear",
    dropdownItems: ["Jeans", "Sweatpants", "Shorts"],
  },
  {
    src: "/headwear.svg",
    name: "Headwear",
    dropdownItems: ["Baseball Caps", "Beanies", "Bucket Hats"],
  },
  {
    src: "/footwear.svg",
    name: "Feetwear",
    dropdownItems: ["Sneakers", "Socks", "Sandals"],
  },
  {
    src: "/accessories.svg",
    name: "Accs.",
    dropdownItems: ["Bags", "Jewelry", "Sunglasses"],
  },
  {
    src: "/electronics.svg",
    name: "Elec.",
    dropdownItems: ["Phone Cases", "Laptop Cases", "Headphones"],
  },
  {
    src: "/others.svg",
    name: "Other",
    dropdownItems: ["Stickers", "Posters", "Mugs"],
  },
];

export default buttonData;
