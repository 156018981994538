
import { useEffect, useState, useRef } from 'react';

import SideControls from './SideControls.js';
import Canvas from './Canvas.js';
import { fabric } from 'fabric';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useIndexedDB } from "react-indexed-db-hook";
import { useLocation, useNavigate } from 'react-router-dom';
import { arrayBufferToDataUrl } from './canvasFunctions';


function TemplateEditor({ toggleRectangle = true, commit, designs = null, changeLog, downloading, customButton, currentDesignImg, setCurrentDesignImg }) {
    const handleDragStart = (e) => e.preventDefault();
    const [collectionName, setCollectionName] = useState('');
    const carousel = useRef(null);
    const [files, setFiles] = useState([]);
    const [currentFile, setCurrentFile] = useState(null);
    const itemsRef = useRef([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [currentImg, setCurrentImg] = useState(null);
    const [currentDBImg, setCurrentDBImg] = useState(null);
    const [currentFileName, setCurrentFileName] = useState(null);
    const [items, setItems] = useState([]);
    const [canvasWidth, setCanvasWidth] = useState(null);
    // rect orientation goes clockwise, 0, 90, 180, 270, 360
    const [rect, setRect] = useState(null);
    const { add, update, getByIndex } = useIndexedDB("templateDB");
    const location = useLocation();
    const [rectData, setRectData] = useState(null);
    const [log, setLog] = useState([]);
    const [turnIntoImg, setTurnIntoImg] = useState(false);
    const [canvas, setCanvas] = useState(null);
    const [carouselHeight, setCarouselHeight] = useState(0);
    const navigate = useNavigate();
    const controls = useRef(null);
    const [changingRect, setChangingRect] = useState(false);
    const [logging, setLogging] = useState(false);
    const prevDesignRef = useRef();
    const [nevinToggle, setNevinToggle] = useState(0);






    useEffect(() => {
        if (prevDesignRef.current) {
            writeToLog(activeIndex, prevDesignRef.current);
            handleFileChange(files[activeIndex], log[activeIndex]).then(() => {
                prevDesignRef.current = currentDesignImg;
            });

        } else {
            prevDesignRef.current = currentDesignImg;

        }
    }, [currentDesignImg])

    const syncActiveIndexForSwipeGestures = (e) => setActiveIndex(e.item);

    const onSlideChanged = (e) => {
        // syncActiveIndexForSwipeGestures(e);
        // console.debug(`onSlideChanged => Item's position after changes: ${e.item}. Event:`, e);
    };



    const writeToLog = (index, prevDesign = null) => {

        localStorage.setItem('canvasWidth', canvasWidth);
        const filename = collectionName + currentFileName;
        const percentage = canvas.width / 100;
        const updatedObject = {
            filename: filename,
            collection: collectionName,
            image_file: currentDBImg,
            rectangle_coords: [rect.left / percentage, rect.top / percentage],
            rectangle_dims: [rect.width / percentage, rect.height / percentage],
            rectangle_orientation: rect.angle,
            file_object: currentFile,
            scaleX: designs == null ? null : rect.scaleX,
            scaleY: designs == null ? null : rect.scaleY,
            canvas_width: canvas.width,
            canvas_height: canvas.height
        }
        //TODO: necessary for create and edit
        // log[index] = updatedObject
        if (designs != null) {
            var logDesignName = currentDesignImg.name
            if (prevDesign != null) {
                logDesignName = prevDesign.name
            }
            console.log("log design name", logDesignName)
            log[index].set(logDesignName, updatedObject)
        } else {
            log[index] = updatedObject;
        }
        // console.log(log[index])
    }

    useEffect(() => {
        if (commit) {
            commitTransaction();
        }
    }, [commit])

    useEffect(() => {
        if (controls) {
            if (changingRect) {
                controls.current.style.visibility = 'hidden'
            } else {
                controls.current.style.visibility = 'visible'
            }
        }
    }, [changingRect])

    useEffect(() => {
        if (downloading) {
            writeToLog(activeIndex)
        }
    }, [downloading])

    const commitTransaction = () => {
        writeToLog(activeIndex)
        async function writeTransaction() {
            for (const value of log) {
                const filename = value.filename;
                const item = await getByIndex("filename", filename)
                if (typeof item !== 'undefined') {
                    await update({
                        id: item.id,
                        filename: value.filename,
                        collection: value.collection,
                        image_file: value.image_file,
                        rectangle_coords: value.rectangle_coords,
                        rectangle_dims: value.rectangle_dims,
                        rectangle_orientation: value.rectangle_orientation,
                        file_object: value.file_object
                    })
                } else {
                    await add(value)
                }
            }
        }

        //go back to home page after making changes
        writeTransaction().then(() => { navigate('/home'); })

    }

    useEffect(() => {
        if (canvas != null && log.length > 0) {
            const index = nevinToggle
            setActiveIndex(index)
            console.log("nevongs", activeIndex, index)
            handleFileChange(files[index], log[index]).then(() => {
                if (toggleRectangle) {
                    writeToLog(activeIndex)
                }
                itemsRef.current[activeIndex].style.border = '';
                itemsRef.current[index].style.border = 'solid rgb(47, 131, 249) 3px';
                console.log("index is", index)
            })
        }

    }, [nevinToggle])

 

    const slideNext = (e) => {
        if (activeIndex < files.length - 1) {
            carousel?.current?.slideNext(e)
            setNevinToggle(activeIndex + 1);
        }
    };

    const slidePrev = (e) => {
        if (activeIndex > 0) {
            carousel?.current?.slidePrev(e)
            setNevinToggle(activeIndex - 1);
        }

    };

    useEffect(() => {
        itemsRef.current = itemsRef.current.slice(0, files.length);
        //writing to log
        async function createLog() {
            for (const file of files) {
                var tempCollectionName = location.state.collectionName
                var filename = tempCollectionName + file.name;
                let item = await getByIndex("filename", filename)
                const designMap = new Map();
                var defaultObject = null
                if (typeof item !== 'undefined') {
                    defaultObject = {
                        filename: filename,
                        collection: tempCollectionName,
                        image_file: item.image_file,
                        rectangle_coords: item.rectangle_coords,
                        rectangle_dims: item.rectangle_dims,
                        rectangle_orientation: item.rectangle_orientation,
                        file_object: item.file_object
                    }
                } else {
                    defaultObject = {
                        filename: filename,
                        collection: tempCollectionName,
                        image_file: await readImageAsDataURL(file),
                        rectangle_coords: [10, 10],
                        rectangle_dims: [20, 20],
                        rectangle_orientation: 0,
                        file_object: file
                    }
                }
                if (designs != null) {
                    designs.forEach((design) => {
                        designMap.set(design.name, defaultObject)
                    })
                    templog.push(designMap)
                } else {
                    templog.push(defaultObject);
                }
            }
        }
        //went under in conditional
        //designs.length > 0 && typeof designs[0].name != 'undefined'
        if (files) {
            setItems(files.map((item, i) => (
                <button key={i} onClick={(e) => { setNevinToggle(i) }}>
                    <img src={URL.createObjectURL(item)}
                        ref={el => itemsRef.current[i] = el} onDragStart={handleDragStart} role="presentation"
                        className='w-20 h-20 rounded-lg mr-0' />
                </button>
            )))

            if (itemsRef.current.length > 0 && activeIndex == 0) {
                itemsRef.current[0].style.border = 'solid rgb(47, 131, 249) 3px';
            }

            if (toggleRectangle) {
                var templog = [];
                createLog().then(() => {
                    setLog(templog)
                    setActiveIndex(0)
                    carousel?.current?.slideTo(0)
                });
            } else {
                handleFileChange(files[0], log[0])
            }
        }

    }, [files]);

    useEffect(() => {
        if (log.length > 0 && collectionName.length > 0) {
            console.log('here')
            handleFileChange(files[0], log[0]).then(() => {
                changeLog(log)
            })
        }
    }, [log])

    useEffect(() => {
        setFiles(location.state.fileNames);
        setCollectionName(location.state.collectionName);
        if (carousel?.current) {
            setCarouselHeight(carousel?.current?.style?.offsetHeight);
        }
    }, []);

    const handleFileChange = async (file, logItem) => {      
        if (file) {
          if (designs != null) {
            logItem = logItem.get(currentDesignImg.name);
          }      
          setCurrentFile(file);
          setCurrentFileName(file.name);
          fetchExistingRectangleData(logItem);
            
          // Use Promise for the image loading process
          const loadImage = (src) => {
            return new Promise((resolve, reject) => {
              const img = new Image();
              img.onload = () => resolve(img);
              img.onerror = reject;
              img.src = src;
            });
          };
      
          // Load the image using async/await
          try {
            const img = await loadImage(logItem.image_file);
            const fabricImage = new fabric.Image(img);
            setCurrentImg(fabricImage);
            setCurrentDBImg(logItem.image_file);
          } catch (error) {
            console.error('Error loading image:', error);
          }
        }
      };

    function readImageAsDataURL(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            // Read the file as Data URL
            reader.readAsDataURL(file);
        });
    }
    const fetchExistingRectangleData = (item) => {
        if (typeof item !== 'undefined') {
            const percentage = canvas.width / 100;
            const tempObject = {
                rectangle_coords: [item.rectangle_coords[0] * percentage, item.rectangle_coords[1] * percentage],
                rectangle_dims: [item.rectangle_dims[0] * percentage, item.rectangle_dims[1] * percentage],
                rectangle_orientation: item.rectangle_orientation,
                scaleX: item.scaleX,
                scaleY: item.scaleY
            };
            setRectData(tempObject);
        } else {
            setRectData(null);
        }
    }

    return (
        <div>
            <div className='create-templates'>
                <div className='flex flex-row w-full h-fit  mb-10'>
                    <Canvas currentDesign={currentDesignImg} setCurrentDesign={setCurrentDesignImg} files={files} setFiles={setFiles} customButton={customButton} changingRect={(x) => setChangingRect(x)} designs={designs} updateCanvas={(x) => setCanvas(x)} turnIntoImg={turnIntoImg} rectData={rectData} actualImg={currentImg} changeCanvasWidth={(x) => setCanvasWidth(x)} changeRect={(x) => setRect(x)} toggleRectangle={toggleRectangle} />
                </div>
                <div className='w-full h-40'></div>
            </div>

            <div className='w-full fixed bottom-0 z-50 bg-white pt-6 h-auto flex flex-row justify-evenly' ref={controls}>
                    <Button icon={<ArrowLeftOutlined />} className='' size={'large'} onClick={(e) => slidePrev(e)}></Button>
                <div className='w-5/6 h-auto pl-20'>
                    <AliceCarousel
                    keyboardNavigation={true}
                        activeIndex={activeIndex}
                        mouseTracking
                        items={items}
                        responsive={{
                            0: {
                                items: 1,
                            },
                            512: {
                                items: 3,
                                itemsFit: 'contain',
                            },
                            1024: {
                                items: 5,
                                itemsFit: 'contain',
                            }
                        }}
                        // controlsStrategy='alternate'
                        ref={carousel}
                        disableButtonsControls
                        onSlideChanged={onSlideChanged}
                    />
                </div>
                    <Button icon={<ArrowRightOutlined />} className='selection-buttons' size={'large'} onClick={(e) => slideNext(e)}></Button>
            </div>

        </div>

    );
}

export default TemplateEditor;